import { Box, Stack, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import { GridColDef, GridSelectionModel } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useCustomDispatch from 'redux/dispatch';
import {
  getLanguages,
  getLogos,
  getOrganizationProfile,
  getTemplates
} from 'redux/slices/organization';
import { fDate } from 'utils/formatTime';
import {
  CustomDataGrid,
  CustomGridToolbar,
  CustomLoadingOverlay,
  CustomPagination
} from '../../../components/datagrid/Custom';
import { RootState, useSelector } from '../../../redux/store';
import { PATH_DASHBOARD } from '../../../routes/paths';
import CountryFlag from '../../../components/icons/CountryFlag';
import Icon from 'components/icons/Icon';
import { DataGridKey } from 'redux/slices/datagrid';
import { EditListItem } from 'components/datagrid/listItems/EditListItem';

// utils

// ----------------------------------------------------------------------

let thumbHeight = 64;

const ThumbImgStyle = styled('img')(({ theme }) => ({
  maxWidth: 70,
  maxHeight: thumbHeight,
  // objectFit: 'cover',
  margin: theme.spacing(0, 2)
  // borderRadius: theme.shape.borderRadiusSm
}));

// ----------------------------------------------------------------------

export default function TemplateList() {
  const DATA_GRID_KEY = DataGridKey.TemplateList;
  const { density } = useSelector((state: RootState) => state.datagrid);
  const { templates, isLoading } = useSelector((state: RootState) => state.organization);
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
  const navigate = useNavigate();
  const customDispatch = useCustomDispatch();

  useEffect(() => {
    customDispatch({ action: getTemplates, disableSuccessMessage: true });
    customDispatch({ action: getLogos, disableSuccessMessage: true });
    customDispatch({ action: getOrganizationProfile, disableSuccessMessage: true });
    customDispatch({ action: getLanguages, disableSuccessMessage: true });
  }, [customDispatch]);

  useEffect(() => {
    if (density[DATA_GRID_KEY] === 'compact') {
      thumbHeight = 32;
    } else if (density[DATA_GRID_KEY] === 'standard') {
      thumbHeight = 64;
    } else if (density[DATA_GRID_KEY] === 'comfortable') {
      thumbHeight = 128;
    }
  }, [density, DATA_GRID_KEY]);

  const columns: GridColDef[] = [
    {
      field: 'cover',
      headerName: 'Logo',
      width: 120,
      align: 'center',
      filterable: false,
      sortable: false,
      renderCell: (params) => {
        return <ThumbImgStyle src={params.row.logoURL} />;
      }
    },
    {
      field: 'id'
    },
    {
      field: 'name',
      headerName: 'Template name',
      flex: 1,
      hide: false
    },
    {
      field: 'language',
      headerName: 'Language',
      flex: 1,
      hide: false,
      valueGetter: (params) => {
        return params.row.language.name;
      },
      renderCell: (params) => {
        return (
          <>
            <CountryFlag country={params.row.language.name} />
            <div style={{ paddingLeft: '.5rem' }}>{params.row.language.name}</div>
          </>
        );
      }
    },
    {
      field: 'notificationTriggers',
      headerName: 'NPS Notifications',
      flex: 1,
      hide: false,
      valueGetter: (params) => {
        return params.row.notificationTriggers?.length ?? 0;
      }
    },
    {
      field: 'created',
      headerName: 'Created',
      flex: 1,
      hide: false,
      renderCell: (params) => {
        const created = params.row.created as string;
        return fDate(created);
      }
    },
    {
      field: 'type',
      headerName: 'Type',
      width: 120,
      hide: false,
      align: 'center',
      valueGetter: (params) => {
        const manualDelivery = params.row.manualDelivery as boolean;
        const universalLinkCampaign = params.row.universalLinkCampaign as boolean;
        const smsCampaign = params.row.smsCampaign as boolean;
        if (manualDelivery) {
          return 'Block send outs';
        }
        if (universalLinkCampaign) {
          return 'Universal Link Campaign';
        }
        if (smsCampaign) {
          return 'SMS Campaign';
        }
        return 'Email Campaign';
      },
      renderCell: (params) => {
        const manualDelivery = params.row.manualDelivery as boolean;
        const universalLinkCampaign = params.row.universalLinkCampaign as boolean;
        const smsCampaign = params.row.smsCampaign as boolean;
        return (
          <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
            {manualDelivery && (
              <Tooltip title="Block send outs">
                <Icon type="manual" />
              </Tooltip>
            )}
            {universalLinkCampaign && (
              <Tooltip title="Link campaign">
                <Icon type="link" />
              </Tooltip>
            )}
            {smsCampaign && (
              <Tooltip title="Sms campaign">
                <Icon type="sms" />
              </Tooltip>
            )}
            {!smsCampaign && (
              <Tooltip title="E-mail campaign">
                <Icon type="email" />
              </Tooltip>
            )}
          </Stack>
        );
      }
    },
    {
      field: 'edit',
      headerName: '',
      width: 60,
      editable: false,
      disableColumnMenu: true,
      hideable: false,
      align: 'center',
      hideSortIcons: true,
      filterable: false,
      sortable: false,
      renderCell: (cellValues) => {
        return (
          <EditListItem
            onClick={() => {
              navigate(`${PATH_DASHBOARD.templates.edit.replace(':id', cellValues.id as string)}`);
            }}
          />
        );
      }
    }
  ];

  return (
    <Box>
      <CustomDataGrid
        dataGridKey={DATA_GRID_KEY}
        autoHeight
        loading={isLoading}
        // checkboxSelection
        disableSelectionOnClick
        rows={templates ? templates.filter((temp) => temp.isTemplate === true) : []}
        columns={columns}
        pagination
        pageSize={25}
        onSelectionModelChange={(newSelectionModel) => {
          setSelectionModel(newSelectionModel);
        }}
        selectionModel={selectionModel}
        components={{
          Toolbar: CustomGridToolbar,
          Pagination: CustomPagination,
          LoadingOverlay: CustomLoadingOverlay
        }}
      />
    </Box>
  );
}
