enum RemoteConfigKey {
  UnderMaintenanceDev = 'under_maintenance_dev',
  UnderMaintenanceSandbox = 'under_maintenance_sandbox',
  UnderMaintenanceStaging = 'under_maintenance_staging',
  UnderMaintenanceProduction = 'under_maintenance_production',
  DownTimeReasonDescription = 'down_time_reason_description',
  DownTimeReasonTitle = 'down_time_reason_title',
  ExpectedBackAt = 'expected_back_at',
  GenesysIvr = 'genesys_ivr'
}
export interface RemoteConfigKeyConfiguration {
  key: RemoteConfigKey;
  type: 'boolean' | 'string' | 'number';
  showInNonProductionEnvironments?: boolean;
}
export const UnderMaintenanceConfigDev: RemoteConfigKeyConfiguration = {
  key: RemoteConfigKey.UnderMaintenanceDev,
  type: 'boolean',
  showInNonProductionEnvironments: false
};
export const DownTimeReasonDesciptionConfig: RemoteConfigKeyConfiguration = {
  key: RemoteConfigKey.DownTimeReasonDescription,
  type: 'string'
};
export const DownTimeReasonTitleConfig: RemoteConfigKeyConfiguration = {
  key: RemoteConfigKey.DownTimeReasonTitle,
  type: 'string'
};
export const ExpectedBackAtConfig: RemoteConfigKeyConfiguration = {
  key: RemoteConfigKey.ExpectedBackAt,
  type: 'string'
};

export const GenesysIvrConfig: RemoteConfigKeyConfiguration = {
  key: RemoteConfigKey.GenesysIvr,
  type: 'boolean',
  showInNonProductionEnvironments: true
};
